import "../../../../application/design/styles/queryBuilder/queryBuilder.scss";

import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import type { ISelectableListItemsDefinition } from "@application/components/_common/QuickSearch/components/SelectableListItems/interface/ISelectableListItemsDefinition.interface";
import QuickSearch from "@application/components/_common/QuickSearch/QuickSearch";
import { EConfirmationModalAction } from "@domain/interfaces/form.interface";
import {
	ApplicationColors,
	ButtonIcon,
	ConfirmationModal,
	type DtoSearchFilterGet,
	SearchBase,
	ThemeColors,
	treeToSieve,
} from "@key4-front-library/core";
import K4QueryBuilder from "@key4-front-library/core/Bo/Components/QueryBuilder";
import {
	Autocomplete,
	Box,
	Collapse,
	Paper,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import {
	type Fields,
	type ImmutableTree,
	Utils as QbUtils,
	BasicConfig,
} from "@react-awesome-query-builder/ui";

import SaveNewQueryModal from "./SaveNewQueryModal";

type PropsSearchList = {
	onSearchChange: (text: string) => void;
	onQueryBuilderChange: (text: string) => void;
	fields: Fields;
	searchFilterQueriesList: Array<DtoSearchFilterGet>;
	onCreateQuerySearchFilterClick: (
		titleQuery: string,
		queryBuilderTree: string,
	) => void;
	openSaveNewQueryModale: boolean;
	onOpenSaveNewQueryModaleClick: (openSaveNewQueryModale: boolean) => void;
	onUpdateQueryFilterClick: (querySearchFilter: DtoSearchFilterGet) => void;
	onDeleteQueryFilterClick: (id: string) => void;
	newQueryFilterId: string | null;
	quickSearchFieldsDefinition?: Array<ISelectableListItemsDefinition>;
	localStoragePrefixKey: string;
};

const SearchList = (props: PropsSearchList) => {
	const {
		onSearchChange,
		onQueryBuilderChange,
		fields,
		searchFilterQueriesList,
		onCreateQuerySearchFilterClick,
		openSaveNewQueryModale,
		onOpenSaveNewQueryModaleClick,
		onUpdateQueryFilterClick,
		onDeleteQueryFilterClick,
		newQueryFilterId,
		quickSearchFieldsDefinition,
		localStoragePrefixKey,
	} = props;

	const queryBuilderTreeRef = useRef<string>("");

	const [search, setSearch] = useState("");
	const [openQueryBuilder, setOpenQueryBuilder] = useState(false);
	const [currentQuerySearchFilter, setCurrentQuerySearchFilter] =
		useState<DtoSearchFilterGet | null>(null);
	const [importQuerySearchFilter, setImportQuerySearchFilter] =
		useState<string>();
	const [
		openDeleteConfirmationQueryModale,
		setOpenDeleteConfirmationQueryModale,
	] = useState<boolean>(false);

	const [searchUrlParams] = useSearchParams();

	const resetSearch = () => {
		onSearchChange("");
		setSearch("");
	};

	const resetQueryBuilder = () => {
		onQueryBuilderChange("");
		queryBuilderTreeRef.current = "";
		setImportQuerySearchFilter(importQuerySearchFilter ? undefined : "");
	};

	const handleToggleSearchClick = () => {
		setOpenQueryBuilder(!openQueryBuilder);
		setCurrentQuerySearchFilter(
			searchFilterQueriesList.length > 0 ? searchFilterQueriesList[0] : null,
		);
		setImportQuerySearchFilter("");
		resetQueryBuilder();
		if (search !== "") {
			resetSearch();
		}
	};

	const handleQueryChange = (tree: ImmutableTree) => {
		const queryString = QbUtils.isValidTree(tree, BasicConfig)
			? (treeToSieve(QbUtils.getTree(tree)) ?? null)
			: null;
		console.log("queryString", queryString);
		onQueryBuilderChange(queryString ?? "");
		queryBuilderTreeRef.current = JSON.stringify(QbUtils.getTree(tree));
	};

	useEffect(() => {
		if (newQueryFilterId) {
			const query = searchFilterQueriesList.find(
				(el) => el.id === newQueryFilterId,
			);
			if (query) {
				setCurrentQuerySearchFilter(query);
			}
		}
	}, [newQueryFilterId, searchFilterQueriesList]);

	useEffect(() => {
		if (searchUrlParams.has("query") && searchUrlParams.get("query")?.length) {
			setOpenQueryBuilder(true);
		}
	}, []);

	return (
		<>
			{quickSearchFieldsDefinition && !openQueryBuilder && (
				<Collapse in={!openQueryBuilder} unmountOnExit>
					<Paper variant="outlined" sx={{ marginBottom: 2 }}>
						<Stack width={"100%"}>
							<Box m={2}>
								<QuickSearch
									localStoragePrefixKey={localStoragePrefixKey}
									quickSearchFieldsDefinition={quickSearchFieldsDefinition}
									handleQueryBuilder={(_query: string) => {
										onQueryBuilderChange(_query);
									}}
								/>
							</Box>
						</Stack>
					</Paper>
				</Collapse>
			)}
			<Paper variant="outlined">
				<Stack
					direction={"row"}
					sx={{ mx: 1.5, my: openQueryBuilder ? 1.5 : 0 }}
				>
					{openQueryBuilder ? (
						<Stack direction="row" alignItems="center" width="100%">
							<Typography variant={"h5"} color="primary">
								{t("old.searchList.title")}
							</Typography>
							<Stack ml="auto">
								<ButtonIcon
									aria-label="actions"
									iconName={"minus"}
									iconSize="lg"
									onClick={handleToggleSearchClick}
									styles={{
										bgcolor: ThemeColors.primary.main,
										color: ApplicationColors.white.main,
										width: "2.5rem",
										height: "2.5rem",
									}}
								/>
							</Stack>
						</Stack>
					) : (
						<>
							<SearchBase
								onChange={(value) => {
									setSearch(value);
									onSearchChange(value);
								}}
								label={t("old.searchList.placeholderSimpleSearch")}
								labelReset={t("old.searchList.searchReset")}
								onResetSearch={resetSearch}
								value={search}
							/>

							<Stack justifyContent="center">
								<Tooltip title={t("old.searchList.advancedSearch")}>
									<div>
										<ButtonIcon
											aria-label="actions"
											iconName={"plus"}
											iconSize="lg"
											onClick={handleToggleSearchClick}
											styles={{
												bgcolor: ThemeColors.primary.main,
												color: ApplicationColors.white.main,
												width: "2.5rem",
												height: "2.6rem",
												my: 0.5,
											}}
										/>
									</div>
								</Tooltip>
							</Stack>
						</>
					)}
				</Stack>
				<Collapse in={openQueryBuilder} unmountOnExit>
					<Stack>
						<Paper elevation={0}>
							<Stack ml={1}>
								<Stack direction={"row"} alignItems={"center"}>
									<Autocomplete
										sx={{ marginTop: ".5rem" }}
										disablePortal
										options={searchFilterQueriesList}
										fullWidth
										getOptionLabel={(option) => option.name ?? ""}
										renderInput={(params) => (
											<TextField
												{...params}
												label={t(
													"old.searchList.searchFilter.autoComplete.title",
												)}
											/>
										)}
										isOptionEqualToValue={(option, value) =>
											option.id === value.id
										}
										onChange={(_, query) => {
											setCurrentQuerySearchFilter(query ?? null);
											setImportQuerySearchFilter(query?.value ?? undefined);
											return query ? query.id : null;
										}}
										value={currentQuerySearchFilter ?? null}
									/>
									<Stack sx={{ margin: ".5rem" }} direction={"row"}>
										{!currentQuerySearchFilter?.id && (
											<ButtonIcon
												disabled={!currentQuerySearchFilter}
												aria-label="actions"
												iconName={"plus"}
												iconSize="lg"
												iconColor={ThemeColors.primary.main}
												onClick={() => {
													if (
														currentQuerySearchFilter &&
														!currentQuerySearchFilter.id
													)
														onOpenSaveNewQueryModaleClick(true);
												}}
												styles={{
													bgcolor: ThemeColors.primary.main,
													color: ApplicationColors.white.main,
													width: "2.5rem",
													height: "2.5rem",
												}}
											/>
										)}
										{currentQuerySearchFilter?.id && (
											<ButtonIcon
												aria-label="actions"
												iconPrefix="fal"
												iconName={"save"}
												iconSize="lg"
												onClick={() => {
													onUpdateQueryFilterClick({
														...currentQuerySearchFilter,
														value: queryBuilderTreeRef.current,
													});
												}}
												styles={{
													width: "2.5rem",
													height: "2.5rem",
													bgcolor: ThemeColors.secondary.main,
													color: ApplicationColors.white.main,
													"&:hover": {
														bgcolor: ThemeColors.secondary.dark,
													},
												}}
											/>
										)}
										<Stack ml={".5rem"}>
											<ButtonIcon
												disabled={!currentQuerySearchFilter?.id}
												aria-label="actions"
												iconName={"trash"}
												iconSize="lg"
												onClick={() => {
													setOpenDeleteConfirmationQueryModale(true);
												}}
												iconColor={ThemeColors.error.main}
												styles={{
													width: "2.5rem",
													height: "2.5rem",
												}}
											/>
										</Stack>
									</Stack>
								</Stack>
								<K4QueryBuilder
									onQueryChange={(tree) => {
										handleQueryChange(tree);
									}}
									fields={fields}
									importQuerySearchFilter={importQuerySearchFilter}
								/>
							</Stack>
						</Paper>
						<SaveNewQueryModal
							isOpen={openSaveNewQueryModale}
							onCloseClick={() => {
								onOpenSaveNewQueryModaleClick(false);
							}}
							onSubmitClick={(titleQuery: string) => {
								onCreateQuerySearchFilterClick(
									titleQuery,
									queryBuilderTreeRef.current,
								);
							}}
						/>
						<ConfirmationModal
							open={openDeleteConfirmationQueryModale}
							action={EConfirmationModalAction.DELETE}
							handleModaleClose={() => {
								setOpenDeleteConfirmationQueryModale(false);
							}}
							handleAction={() => {
								setCurrentQuerySearchFilter(searchFilterQueriesList[0]);
								onDeleteQueryFilterClick(currentQuerySearchFilter!.id);
								resetQueryBuilder();
								setOpenDeleteConfirmationQueryModale(false);
							}}
							maxWidth={"sm"}
						/>
					</Stack>
				</Collapse>
			</Paper>
		</>
	);
};

export default SearchList;
